<template>
  <v-container :fluid="$vuetify.breakpoint.md" v-if="menu">
    <ValidationObserver ref="form" v-slot="{ invalid }">
      <v-row justify="space-between">
        <v-col cols="12" sm="5" lg="4">
          <ValidationProvider
            v-slot="{ errors }"
            name="Name"
            rules="required|min:3"
            immediate
          >
            <v-text-field
              slot="input"
              label="Name of menu"
              v-model="menu.name"
              :error-messages="errors"
              autofocus
            ></v-text-field>
          </ValidationProvider>
        </v-col>
        <v-col cols="12" sm="5" lg="4">
          <v-text-field
            slot="input"
            label="Description"
            v-model="menu.description"
            :hide-details="true"
            autofocus
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="2" class="d-flex pb-0">
          <v-btn
            color="info"
            small
            @click.stop="saveMenu"
            class="mr-3"
            :disabled="invalid"
          >
            <v-icon>mdi-content-save</v-icon>Save
          </v-btn>
          <v-btn @click.stop="saveMenu" small :to="'/product/menu/' + uid">
            <v-icon>mdi-exit-to-app</v-icon>Back
          </v-btn>
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col cols="4">
          <v-tooltip
            bottom
            :disabled="
              (menu.children && menu.children.length > 0) ||
                (menu.items && menu.items.length > 0)
            "
          >
            <template v-slot:activator="{ on }">
              <div v-on="on">
                <v-checkbox
                  color="info"
                  label="Is Active?"
                  v-model="menu.isActive"
                  :disabled="isActiveDisabled()"
                  :hide-details="true"
                  @change="changeVisibility"
                ></v-checkbox>
              </div>
            </template>
            <span class="white--text"
              >You Must Add Items to the Menu First!</span
            >
          </v-tooltip>
        </v-col>
        <v-col cols="3" v-if="false">
          <v-checkbox
            color="info"
            label="Is Public?"
            v-model="menu.isPublic"
            :hide-details="true"
            :disabled="!menu.isActive"
            @change="isPublicOrFollower('public')"
          ></v-checkbox>
        </v-col>
        <v-col cols="5" v-if="menu.type != 'parent'">
          <v-checkbox
            color="info"
            :label="$t('Common.followers_only')"
            :hide-details="true"
            :disabled="!menu.isActive"
            v-model="menu.followerOnly"
            @change="isPublicOrFollower('follower')"
          ></v-checkbox>
        </v-col>
      </v-row>

      <v-row align="center">
        <v-col cols="6" md="4">
          <v-checkbox
            label="Use Business Hours"
            color="info"
            :disabled="useParentHours"
            v-model="useBusinessHours"
          ></v-checkbox>
        </v-col>

        <v-col cols="6" md="4" v-if="showParentHours">
          <v-checkbox
            label="Use Parent Menu Hours"
            color="info"
            v-model="useParentHours"
          ></v-checkbox>
        </v-col>
        <v-col cols="6" sm="4" v-if="parents && parents.length">
          Parents:
          <template v-for="parent in parents">
            <v-chip
              dark
              label
              :to="`/product/menu/${parent.uid}/editmenu/${parent.id}`"
              :key="parent.id"
              :color="parent.isActive ? 'success' : 'red'"
              class="mr-1 ma-2"
            >
              {{ parent.name }}
            </v-chip>
          </template>
        </v-col>
      </v-row>
      <v-row v-show="!useBusinessHours && !useParentHours">
        <v-col cols="12" sm="6" md="6">
          <ValidationProvider
            v-slot="{ errors }"
            name="Week days"
            immediate
            :rules="`${!useBusinessHours && !useParentHours ? 'required' : ''}`"
          >
            <v-select
              multiple
              :items="[
                'Monday',
                'Tuesday',
                'Wednesday',
                'Thursday',
                'Friday',
                'Saturday',
                'Sunday'
              ]"
              :error-messages="errors"
              v-model="menu.weekday"
              label="Week days"
            ></v-select>
          </ValidationProvider>
        </v-col>

        <v-col cols="6" sm="3">
          <ValidationProvider
            v-slot="{ errors }"
            name="from"
            :rules="`${!useBusinessHours && !useParentHours ? 'required' : ''}`"
            immediate
          >
            <v-select
              :items="startHour"
              v-model="menu.fromHour"
              label="from"
              :error-messages="errors"
              @change="menu.endHour = null"
            ></v-select>
          </ValidationProvider>
        </v-col>

        <v-col cols="6" sm="3" md="3">
          <ValidationProvider
            v-slot="{ errors }"
            name="to"
            :rules="
              `${
                !useBusinessHours && !useParentHours
                  ? 'required|fromTo:@from,@to'
                  : ''
              }`
            "
            immediate
          >
            <v-select
              :items="endHour"
              :error-messages="errors"
              v-model="menu.endHour"
              label="to"
            ></v-select>
          </ValidationProvider>
        </v-col>
      </v-row>

      <v-row> </v-row>

      <v-row v-if="!loading && menus && menu.type" justify="center">
        <v-col cols="12" md="6" lg="5" xl="4">
          <v-card>
            <v-card-text>
              <vue-perfect-scrollbar style="height:240px" :settings="settings">
                <v-list v-if="menu.children && menu.children.length > 0">
                  <draggable
                    :list="menu.children"
                    style="height:240px"
                    group="submenus"
                    @change="evt => addNewMenu(evt, menu)"
                  >
                    <v-list-item
                      v-for="(submenuId, key) in menu.children"
                      :key="key"
                    >
                      <template>
                        <v-list-item-content
                          v-html="getSubMenuName(submenuId)"
                        ></v-list-item-content>
                        <v-list-item-action>
                          <v-icon
                            color="red"
                            @click="removeMenu(submenuId, menu.id)"
                            small
                            >clear</v-icon
                          >
                        </v-list-item-action>
                      </template>
                    </v-list-item>
                  </draggable>
                </v-list>
                <draggable
                  v-else
                  :list="menu.children"
                  group="submenus"
                  style="height:240px"
                  @change="evt => addNewMenu(evt, menu)"
                  >Drag and drop menus here.</draggable
                >
              </vue-perfect-scrollbar>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="!loading && menus && !menu.type" justify="center">
        <v-col cols="12" md="6" lg="5" xl="4">
          <v-card>
            <v-card-text>
              <vue-perfect-scrollbar style="height:240px" :settings="settings">
                <v-list v-if="menu.items && menu.items.length > 0">
                  <draggable
                    :list="menu.items"
                    group="products"
                    @change="evt => addNewProduct(evt, menu)"
                  >
                    <v-list-item
                      v-for="(product, key) in productInMenu(menu)"
                      :key="key"
                    >
                      <template v-if="product">
                        <v-list-item-avatar v-if="getProductImage(product.id)">
                          <img :src="getProductImage(product.id)" />
                        </v-list-item-avatar>

                        <v-list-item-content
                          >{{ product.name }}
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-btn
                            icon
                            color="info"
                            :to="`/product/edit/${uid}/${product.id}`"
                          >
                            <v-icon small>edit</v-icon>
                          </v-btn>
                        </v-list-item-action>
                        <v-list-item-action>
                          <v-icon
                            color="red"
                            @click="removeProduct(product.id, menu.id)"
                            small
                            >clear</v-icon
                          >
                        </v-list-item-action>
                      </template>
                    </v-list-item>
                  </draggable>
                </v-list>
                <draggable
                  v-else
                  :list="menu.items"
                  group="products"
                  style="height:240px"
                  @change="evt => addNewProduct(evt, menu)"
                  >Drag and drop items here.</draggable
                >
              </vue-perfect-scrollbar>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <div v-if="menu.type != 'parent'">
        <v-row align="center" no-gutters>
          <v-col cols="2">
            <h4>Products</h4>
          </v-col>
          <v-col cols="8">
            <search-product
              :dishList="dishList"
              @filterProduct="filterProduct"
            />
          </v-col>
          <v-col cols="2">
            <v-checkbox
              value="true"
              label="Not in menu?"
              v-model="notInMenu"
            ></v-checkbox>
          </v-col>
        </v-row>

        <v-row class="mb-4">
          <v-col cols="12" class="d-flex my-0 py-0">
            <template v-if="filteredDishListIds && filteredDishListIds.length">
              <draggable
                :list="filteredDishListIds"
                :group="{ name: 'products', pull: 'clone', put: false }"
                class="d-flex flex-row overflow-auto"
                handle=".product-handle"
              >
                <div v-for="(pID, key) in filteredDishListIds" :key="key">
                  <v-card class="ma-4" width="200">
                    <v-img
                      v-if="getProductImage(pID)"
                      class="image-rect"
                      :src="getProductImage(pID)"
                    >
                      <div class="product-handle">
                        <v-icon class="white--text">mdi-cursor-move</v-icon>
                      </div>
                    </v-img>
                    <div v-else class="image-rect">
                      <div class="product-handle">
                        <v-icon class="white--text">mdi-cursor-move</v-icon>
                      </div>
                      <div class="d-flex justify-center mt-2">
                        <v-icon color="grey">mdi-image-off</v-icon>
                      </div>
                      <span class="d-flex justify-center grey--text"
                        >No Image</span
                      >
                    </div>
                    <v-card-title class="d-flex justify-center">
                      <h4>{{ getProductName(pID) }}</h4>
                    </v-card-title>
                  </v-card>
                </div>
              </draggable>
            </template>
            <template v-else>
              <span class="pa-4">Not available products</span>
            </template>
          </v-col>
        </v-row>
      </div>

      <!-- SUB MENUS -->
      <div v-else>
        <v-row align="center">
          <v-col cols="2" md="1">
            <h4>Menus</h4>
          </v-col>
          <v-col cols="5">
            <v-text-field
              placeholder="Search menu name"
              v-model="search"
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-checkbox
              value="true"
              label="Not in menu?"
              v-model="notInMenu"
            ></v-checkbox>
          </v-col>
        </v-row>
        <v-row class="mb-4">
          <v-col cols="12">
            <draggable
              :list="filteredMenuIds"
              :group="{ name: 'submenus', pull: 'clone', put: false }"
              class="d-flex flex-row overflow-auto"
            >
              <div v-for="(menuId, key) in filteredMenuIds" :key="key">
                <v-card class="ma-4" width="200">
                  <v-card-title class="ma-2 pa-2 light-blue lighten-5">
                    <h4 v-html="getSubMenuName(menuId)"></h4>
                  </v-card-title>
                </v-card>
              </div>
            </draggable>
          </v-col>
        </v-row>
      </div>
      <delete-confirmation-dialog
        ref="deleteConfirmationDialog"
        heading="Deleting Menu"
        message="Are you sure you want to delete this menu?"
        @onConfirm="onDeleteMenu"
      ></delete-confirmation-dialog>

      <v-row>
        <v-snackbar :timeout="6000" color="red" v-model="editSnackbar">
          {{ snackBarContent }}
          <v-btn color="white" text @click="editSnackbar = false">Close</v-btn>
        </v-snackbar>
      </v-row>
      <pagination-alert
        ref="paginationAlert"
        heading="Warning"
        message="Are you sure you want to leave this page without saving contents?"
      ></pagination-alert>
    </ValidationObserver>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import draggable from 'vuedraggable'
import { globalMixin } from 'fod-core'
import SearchProduct from './SearchProduct'
import moment from 'moment'
import { extend } from 'vee-validate'

extend('secret', {
  validate: value => value === 'example',
  message: 'This is not the magic word'
})
export default {
  mixins: [globalMixin],

  data: () => {
    return {
      useBusinessHours: true,
      useParentHours: false,
      products: [],
      targetProduct: '',
      totalMenus: 0,
      targetDay: '',
      tmp: null,
      menu_id: null,
      menu: null,
      menus: [],
      startHour: null,
      endHour: null,
      editSnackbar: false,
      snackBarContent: '',
      search: null,
      notInMenu: false,
      subMenus: [],
      parents: [],
      targetMenu: '',
      draggableSubMenu: [],
      ordering_target: null,
      filteredList: [],
      settings: {
        maxScrollbarLength: 160
      }
    }
  },
  computed: {
    ...mapGetters({
      loading: 'loading',
      profile: 'profile',
      dishList: 'dishList',
      isAdmin: 'isAdmin'
    }),
    dragOptions() {
      return {
        group: 'description'
      }
    },
    filteredMenuIds() {
      let list = this.subMenus
      if (this.search) {
        list = this.subMenus.filter(p =>
          p.name.toLowerCase().includes(this.search.toLowerCase())
        )
      }
      if (this.notInMenu) {
        if (this.menus[0] && this.menus[0].children) {
          list = list.filter(p => !this.menus[0].children.includes(p.id))
        }
      }
      return list.map(sm => sm.id)
    },
    filteredDishListIds() {
      let list = this.filteredList
      if (this.notInMenu) {
        if (this.menus[0] && this.menus[0].items) {
          list = list.filter(p => !this.menus[0].items.includes(p.id))
        }
      }
      return list.map(d => d.id)
    },
    showParentHours() {
      if (this.parents && this.parents.length) {
        return this.parents.find(p => !p.useBusinessHours)
      }
      return false
    }
  },
  components: {
    draggable,
    SearchProduct,
    PaginationAlert: () =>
      import('fod-core/src/components/common/PaginationAlert.vue')
  },
  watch: {
    useBusinessHours(newValue) {
      if (newValue) {
        this.menu.fromHour = null
        this.menu.toHour = null
        this.menu.weekday = null
      }
    },
    useParentHours() {
      if (this.parents && this.parents.length) {
        this.useBusinessHours = !this.useParentHours
      }
    }
  },
  async mounted() {
    this.uid =
      this.isAdmin &&
      this.$route.params.uid &&
      this.$route.params.uid !== 'default'
        ? this.$route.params.uid
        : this.profile
        ? this.profile.id
        : null
    this.loadMenu(this.$route.params.id)
    this.$store.dispatch('loadDishList', { uid: this.uid }).then(dishes => {
      this.products = dishes
      this.filteredList = dishes
    })

    this.startHour = this.allHalfHours()
    this.endHour = this.allHalfHours()

    if (
      this.profile &&
      this.profile.businessHours &&
      this.profile.businessHours.length > 0 &&
      this.useBusinessHours === undefined
    ) {
      this.useBusinessHours = true
    }
  },
  // beforeRouteLeave(to, from, next) {
  // this.$refs.paginationAlert.openDialog()
  // this.$nextTick(() => {
  //   this.$refs.paginationAlert.$once('onYes', () => {
  //     next(true)
  //   })
  //   this.$refs.paginationAlert.$once('onCancel', () => {
  //     next(false)
  //   })
  // })
  // },
  methods: {
    filterProduct(productList) {
      this.filteredList = productList
    },
    loadMenu(id) {
      this.$store.dispatch('loadMenus', { uid: this.uid }).then(data => {
        this.subMenus = data.filter(menu => !menu.type)
        this.menu = data.find(menu => menu.id === id)

        if (!this.menu) return
        this.useBusinessHours =
          this.menu.useBusinessHours || this.menu.useBusinessHours != false

        this.useParentHours = this.menu.useParentHours || false
        this.menus.push(this.menu)
        if (this.menu.type !== 'parent') {
          this.parents = data.filter(
            menu => menu.children && menu.children.includes(id)
          )
        }
      })
    },
    getSubMenuName(id) {
      const smenu = this.subMenus.filter(sm => sm.id === id)[0]

      if (smenu)
        return `${smenu.name} <span class="caption info--text">(${
          smenu.items ? smenu.items.length : 0
        } items)</span>`
    },
    deleteMenu(id) {
      this.menu_id = id
      this.$refs.deleteConfirmationDialog.openDialog()
    },
    onDeleteMenu() {
      // console.log('deleting', this.menu_id)
      this.$store.dispatch('deleteMenu', this.menu_id).then(() => {
        this.loadMenus()
        this.$refs.deleteConfirmationDialog.close()
      })
    },
    getProductImage(productID) {
      const product = this.products.filter(p => p.id === productID)[0]
      return product && product.photos && product.photos.length > 0
        ? product.photos[0].url || product.photos[0].imageUrl
        : ''
    },
    getProductName(productID) {
      const product = this.products.filter(p => p.id === productID)[0]
      return product ? product.name : ''
    },
    addNewMenu: function(evt, menu) {
      menu.children = [...new Set(menu.children)]
      // this.$store.dispatch('saveMenus', { menu: this.menu, uid: this.uid })
    },
    removeProduct: function(id, mid) {
      console.log(`Remove ${id} from  ${mid}`)

      if (!this.menu) {
        return
      }
      const list = this.menu.items.filter(item => item !== id)
      this.menu.items = list
      this.changeVisibility()
      this.$store
        .dispatch('saveMenus', { menu: this.menu, uid: this.uid })
        .then(() => {
          this.$store.dispatch('setLoading', false)
          this.$store.dispatch('setMessage', { title: 'Saved' })
        })
    },
    removeMenu: function(subId, parentId) {
      console.log(`Removed ${subId} from  ${parentId}`)
      if (!this.menu) {
        return
      }
      const list = this.menu.children.filter(item => item !== subId)
      this.menu.children = list

      this.changeVisibility()
      this.$store
        .dispatch('saveMenus', { menu: this.menu, uid: this.uid })
        .then(() => {
          this.$store.dispatch('setLoading', false)
          this.$store.dispatch('setMessage', { title: 'Saved' })
        })
    },
    productInMenu(items) {
      let list = []

      if (!items) {
        return
      }
      items.items.forEach(pid => {
        if (pid.length > 0) {
          let product = this.products.filter(p => p.id === pid)
          list.push(product[0])
        }
      })

      return list
    },

    // this is called when a new product is dropped into a day
    addNewProduct: function(evt, smenu) {
      smenu.items = [...new Set(smenu.items)]
      // this.$store.dispatch('saveMenus', { menu: this.menu, uid: this.uid })
    },

    checkScheduleWithinBusinessHour() {
      // menu does not have designated time, do not need to check menu schedule

      if (this.useBusinessHours || this.useParentHours) {
        return true
      }

      if (!this.menu.fromHour || !this.menu.endHour) {
        this.snackBarContent = 'Missing Schedule Time!'
        return false
      }

      if (
        (this.profile && !this.profile.businessHours) ||
        this.profile.businessHours.length === 0
      ) {
        this.snackBarContent = 'This chef has invalid business hour!'
        return false
      }

      let businessWeekDays = [],
        businessDays = [],
        businessStartHours = [],
        businessEndHours = []

      businessWeekDays = [
        ...this.profile.businessHours.map(d => d.business_days)
      ]
      for (let weekdays of businessWeekDays) {
        businessDays = [...businessDays, ...weekdays]
      }
      businessStartHours = [...this.profile.businessHours.map(d => d.fromHour)]
      businessEndHours = [...this.profile.businessHours.map(d => d.endHour)]
      businessDays = Array.from(new Set(businessDays))
      businessStartHours = Array.from(new Set(businessStartHours)).sort(
        (a, b) => this.convert2Sec(a) - this.convert2Sec(b)
      )
      businessEndHours = Array.from(new Set(businessEndHours)).sort(
        (a, b) => this.convert2Sec(b) - this.convert2Sec(a)
      )

      const businessStartTime = businessStartHours[0],
        businessEndTime = businessEndHours[0]

      if (
        moment(businessStartTime, 'h:mma').isAfter(
          moment(this.menu.fromHour, 'h:mma')
        ) ||
        moment(businessEndTime, 'h:mma').isBefore(
          moment(this.menu.endHour, 'h:mma')
        )
      ) {
        this.snackBarContent = 'The schedule times are out of business hour!'
        return false
      }

      if (!this.menu.weekday || !this.menu.weekday.length) {
        this.snackBarContent = 'You should have business day!'
        return false
      }

      for (let i = 0; i < this.menu.weekday.length; i++) {
        if (!businessDays.includes(this.menu.weekday[i])) {
          this.snackBarContent = 'The menu week days are out of business days!'
          return false
        }
      }

      return true
    },
    saveMenu() {
      if (this.checkScheduleWithinBusinessHour()) {
        this.$store.dispatch('setLoading', true)
        this.menu.useBusinessHours = this.useBusinessHours
        this.menu.useParentHours = this.useParentHours || false
        this.$store
          .dispatch('saveMenus', { menu: this.menu, uid: this.uid })
          .then(() => {
            this.$store.dispatch('setLoading', false)
            this.$store.dispatch('setMessage', { title: 'Saved' })
          })
          .finally(() => {
            this.$store.dispatch('setLoading', false)
          })
      } else {
        this.editSnackbar = true
      }
    },
    convertTime(time) {
      if (time) {
        // converts 12 hour to 24 hour
        const [hour, modifier] = time.split(' ')
        let [hours, minutes] = hour.split(':')
        if (hours === '12') {
          hours = '00'
        }
        if (modifier === 'pm') {
          hours = parseInt(hours, 10) + 12
        }
        return `${hours}:${minutes}`
      }
    },
    convert2Sec(time) {
      let ctime = time
      const hours = parseInt(ctime.substr(0, 2))

      if (ctime.indexOf('am') != -1 && hours == 12) {
        ctime = ctime.replace('12', '0')
      }
      if (ctime.indexOf('pm') != -1 && hours < 12) {
        ctime = ctime.replace(hours, hours + 12)
      }

      ctime = ctime.replace(/(am|pm)/, '')
      const [hour, min] = ctime.split(':')

      return hour * 3600 + min * 60
    },
    changeVisibility() {
      // turns visibility off if there are no items in menu
      if (this.menu.type && this.menu.type === 'parent') {
        if (!this.menu.children || this.menu.children.length == 0) {
          this.menu.isActive = false
        }
      } else {
        if (!this.menu.items || this.menu.items.length == 0) {
          this.menu.isActive = false
        }
      }
      // check if isActive has been toggled
      if (!this.menu.isActive) {
        this.menu.isPublic = false
        this.menu.followerOnly = false
      }
    },

    isActiveDisabled() {
      if (this.menu.type && this.menu.type === 'parent') {
        return !this.menu.children || this.menu.children.length === 0
      } else {
        return !this.menu.items || this.menu.items.length === 0
      }
    },
    // menu cannot be both public and follower only
    isPublicOrFollower(change) {
      if (change === 'public' && this.menu.isPublic) {
        this.menu.followerOnly = false
      } else if (change == 'follower' && this.menu.followerOnly) {
        this.menu.isPublic = false
      }
    }
  }
}
</script>
<style scoped>
.image-round {
  border: gainsboro 2px solid;
  border-radius: 50%;
  overflow: hidden;
  width: 40px;
  height: 40px;
}
.image-rect {
  border: gainsboro 4px solid;
  overflow: hidden;
  width: 100%;
  height: 135px;
}
.product-handle {
  padding: 4px;
  background-color: rgba(0, 0, 0, 0.4);
  cursor: pointer;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2) !important;
}
</style>
